import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { PagePath, Button, LinkButton, Form, DataTable, BulkUploadTemplate, IconCSV } from "../../components";
import { taskActions, getBulkUploadsHistory, getBulkUploadStatus } from "../../store";
import Fields from "./FormFields.json";

const Columns = [
  { title: 'Filename', dataIndex: 'document_file_name', key: 'document_file_name', width: '90%', search: true, sort: true },
  { title: 'Upload Date', dataIndex: 'created_at', key: 'created_at', width: '180px', sort: true },
  { title: 'Task Created', dataIndex: 'created', key: 'created', width: '120px', classNames: { cell: 'txt-right' } },
  { title: 'Task Creation Failed', dataIndex: 'failed', key: 'failed', width: '175px', classNames: { cell: 'txt-right' } },
  { title: 'Download', dataIndex: 'frequency', key: 'frequency', width: '100px', render: 'btn', classNames: { cell: 'self-ctr' } },
]

const Steps = [
  "Download the Template provided below.",
  "Prepare existing Tasks as per the Template.",
  "Import prepared Tasks when ready!"
]

export const BulkUploadScreen = () => {
  const _ref = React.useRef(null)
  const dispatch = useDispatch()
  const [uploadStatus, setUploadStatus] = React.useState(false);
  const status = useSelector(getBulkUploadStatus)
  const history = useSelector(getBulkUploadsHistory)
  const [height, setHeight] = React.useState(0)
  React.useEffect(() => {
    dispatch(taskActions.fetchBulkUploads())
  }, [])
  React.useEffect(() => {
    if (!height && _ref.current) {
      setHeight(_ref.current.clientHeight)
    }
  }, [_ref])
  React.useEffect(() => {
    if (status) {
      setUploadStatus(status);
      dispatch(taskActions.setBulkUploadStatus(false))
    }
  }, [status])
  const handleDownloadHistory = (e, upload) => {
    if (upload && upload.error_file_path) {
      dispatch(taskActions.downloadBulkUploadHistory(upload))
    }
  }
  const getColumns = () => {
    let columns = Columns.map((_) => ({ ..._ }));
    columns[columns.length - 1] = {
      ...columns[columns.length - 1],
      cellClassName: 'download-cell',
      onClick: handleDownloadHistory,
      icon: <DownloadOutlined style={{ fontSize: 20, color: '#1133CC' }} />
    }
    return columns;
  }
  const handleBulkUpload = (e) => {
    let files = e.target.value;
    if (Array.isArray(files) && files.length > 0) {
      files = files.map((_) => _.originFileObj);
      const payload = new FormData();
      payload.append('file', files[0])
      dispatch(taskActions.bulkUploadTasks(payload))
    }
  }
  return (
    <div className="col w-100 h-100 o-hide bgFFF upload-task screen-pad">
      <div className="col w-100 header">
        <div className="row w-100 item ">
          <PagePath
            paths={[{ title: 'Tracker', to: '/' }, { title: 'Upload Tasks' }]}
          />
        </div>
        <div className="row exo2 f20 bold line line-17 c238787 item">
          Upload Tasks
        </div>
      </div>

      <div className="col w-100 f-rest o-hide">
        <div className="col w-100 h-100 0-hide">
          <div className="row w-100 v-start">
            <div className="col f-rest h-btn h-100">
              <div className="col w-100 ">
                {
                  Steps.map((step, i) => {
                    return (
                      <div className="row f16 00085" key={i}>
                        <span className="bold step">Step {i + 1}:</span> {step}
                      </div>
                    )
                  })
                }
              </div>
              <div className="row w-100 template-download">
                <IconCSV className="f30 c000" />
                <div className="row f-rest">
                  <span className="f14 c1133CC">Tasks_Upload_Template.xlsx</span>
                </div>
                <Button
                  type="link"
                  href={BulkUploadTemplate}
                  download="Bulk Upload Template.xlsx" target="_blank"
                  icon={<DownloadOutlined style={{ fontSize: 20, color: '#1133CC' }} />}
                />
              </div>
            </div>
            <Form
              className="upload-sec"
              formData={{ upload: [] }}
              onChange={handleBulkUpload}
              Fields={[Fields.BulkUpload]}
            />
          </div>
          <div className="col w-100 f-rest 0-hide">
            <div className="row w-100 h-100 0-hide result-sec v-start">
              {
                Boolean(uploadStatus) &&
                <div className="col upload-result">
                  <div className="row header bgFAFAFA">
                    <span className="exo2 f18 bold-600 c00085">Upload Result</span>
                  </div>
                  <div className="col w-100 f16 result">
                    {
                      uploadStatus === 'started' ?
                        <div className="col w-100 h-100 v-ctr h-ctr result-loading">
                          <LoadingOutlined style={{ color: "#0133CC", fontSize: 30 }} />
                        </div>
                        :
                        <p>
                          Tasks_Upload_File.csv was successfully uploaded with the following results…<br /> <br />
                          Tasks created: {uploadStatus.created} <br />
                          Tasks creation <span className="cF00">failed</span>: {uploadStatus.failed} <br /> <br />
                          Task upload errors: <LinkButton onClick={handleDownloadHistory.bind(null, null, uploadStatus)} variant='lite' className="c1133CC" label={uploadStatus.document_file_name} /> <br /><br />
                          Fix the errors & re-upload them please!
                        </p>
                    }
                  </div>
                </div>
              }
              <div className="col f-rest h-100 o-hide">
                <div className="row header bgFAFAFA">
                  <span className="exo2 f18 bold-600 c00085">Upload History</span>
                </div>
                <div className="col f-rest o-hide" ref={_ref}>
                  <div className="col w-100 h-100 o-hide" style={{ height }}>
                    <DataTable
                      noSelection
                      emptyMessage="There are no History right now!"
                      Columns={getColumns()}
                      rows={history}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}