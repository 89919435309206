import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { HomeScreen, LoginScreen, PageNotFoundScreen, DashboardScreen, TaskViewScreen, BulkUploadScreen } from "../screens";

function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/*" element={<MainRouter />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/page-not-found" element={<PageNotFoundScreen />} exact />
      </Routes>
    </BrowserRouter>
  );
}
const TaskResponder = () => {
  return (
    <Routes>
      <Route path="/upload" element={<BulkUploadScreen />} />
      <Route path="/:taskId" element={<TaskViewScreen />} />
      <Route path="/" index element={<DashboardScreen />} />
      <Route path="" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
const MainRouter = () => {
  return (
    <HomeScreen>
      <Routes>
        <Route path="/T/*" index element={<TaskResponder />} />
        <Route path="/" element={<Navigate replace to="/T" />} />
        <Route path="" element={<Navigate replace to="/" />} />
      </Routes>
    </HomeScreen>
  )
}
export default AppRouter;
