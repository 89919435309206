import React from 'react';
import "./style.scss";

const CustomIcon = (props) => {
  const { iconClassName, className, ...rest } = props
  return <i className={`non-antd-icon ${iconClassName} ${className || ''}`} {...rest} />
}

export const IconRecent = (props) => {
  return <CustomIcon iconClassName='icon-recent' {...props} />
}
export const IconAdd = (props) => {
  return <CustomIcon iconClassName='icon-add' {...props} />
}
export const IconCSV = (props) => {
  return <CustomIcon iconClassName='icon-file-csv' {...props} />
}
export const IconAttachment = (props) => {
  return <CustomIcon iconClassName='icon-attachment' {...props} />
}
export const IconUserCheck = (props) => {
  return <CustomIcon iconClassName='icon-user-check' {...props} />
}