import React from "react";
import { FileTextOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getUserAvatorName, taskActions, commonActions, getCurrentTask } from "../../store";
import { Form, Button, PrimaryButton, IconAttachment } from "../../components";
import FormFields from "./FormFields.json";


export const TaskHistory = (props) => {
  const dispatch = useDispatch();
  const task = useSelector(getCurrentTask)
  const _formRef = React.useRef(null);
  const _historyRef = React.useRef(null);
  const [state, setState] = React.useState({ comment: {} })
  const currentUser = useSelector(getCurrentUser);
  React.useEffect(() => {
    if (task) {
      if (_historyRef.current) {
        _historyRef.current.scrollTop = _historyRef.current.scrollHeight;
      }
    }
  }, [task])
  const canEdit = () => {
    let access = true;
    return access;
  }
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const comment = state.comment;
    comment[name] = value;
    setState((_) => ({ ..._, comment: comment }))
  }
  const handleCommentSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    const payload = new FormData();
    let shouldUpdate = false;
    let documents = state.comment['documents'],
      comment = String(state.comment['comment'] || '').trim();
    if (Array.isArray(documents) && documents.length > 0) {
      shouldUpdate = true;
      documents.forEach((file, i) => {
        payload.append(`documents[attachment${i + 1}]`, file)
      })
    }
    if (shouldUpdate || comment.length > 0) {
      shouldUpdate = true;
      payload.append(`comment[comment]`, comment)
    }
    if (shouldUpdate) {
      payload.append('task_id', JSON.stringify(task.id))
      dispatch(taskActions.addTaskComment(payload))
    }
    setState((_) => ({ ..._, comment: {} }))
  }
  const handleDeleteFile = (attribute, index) => {
    const comment = state.comment;
    if (Array.isArray(comment[attribute]) && comment[attribute].length > 0) {
      comment[attribute].splice(index, 1)
    }
    setState((_) => ({ ..._, comment: { ...comment } }))
  }
  const handleEvidenceDownload = (attachment, e) => {
    e.preventDefault();
    if (attachment && attachment.encrypt_evidence_url) {
      dispatch(commonActions.downloadAttachment({ attachment: attachment }))
    }
  }
  return (
    <div className="col history-sec h-100">
      <div className="col header h-ctr bgFAFAFA">
        <span className="f18 exo2 c0085 line-12 caps">History</span>
      </div>
      <div className="col f-rest o-hide">
        <div className="col h-100 oy-auto mr1" ref={_historyRef}>
          {
            (Array.isArray(task.comments) && task.comments.length > 0) ?
              task.comments.map((comment, i) => {
                // console.log("comment", comment);
                return (
                  Boolean(comment.creator) &&
                  <div className="row w-100 v-start comment-card" key={comment.id}>
                    <div className="col h-ctr v-ctr avator f14 c444444">
                      {Boolean(comment.creator) ? getUserAvatorName(comment.creator) : null}
                    </div>
                    <div className="col f-rest comment-sec">
                      {
                        Boolean(comment.creator) &&
                        <div className="row w-100 v-start">
                          <div className="col f-rest c00045 reg">
                            <span className="f12">{comment.creator_name}</span>
                            <span className="f12">{comment.creator.designation}, {comment.creator.department_name}</span>
                          </div>
                          <span className="reg f10 cBFBFBF">{comment.created_at}</span>
                        </div>
                      }
                      {
                        Boolean(comment.comment.length > 0) &&
                        <p className="c00085 f16 reg comment-text txt-justify" dangerouslySetInnerHTML={{ __html: comment.comment }}
                        ></p>
                      }
                      {
                        Array.isArray(comment.attachments) && comment.attachments.map((attachment, i) => {
                          let filename = attachment.document_file_name;
                          let label = `Task ${task.task_id}-${attachment.appendixID}: ${filename}`
                          return (
                            <div className="attached-file" >
                              <Button
                                type="link"
                                icon={<IconAttachment className='c0133CC' />}
                                labelClassName='f14 c0133CC'
                                className='link-evd' key={attachment.id} label={label}
                                onClick={handleEvidenceDownload.bind(null, attachment)}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
              :
              <div className="exo2 col w-100 h-100 v-ctr h-ctr c777 f10">
                There are no history right now!
              </div>
          }
        </div>
      </div>
      {
        canEdit() &&
        <div className="row footer v-start mr1">
          <div className="col h-ctr v-ctr avator f14 c444444">
            {getUserAvatorName(currentUser)}
          </div>
          <div className="col w-100">
            <Form
              hideErrorMsg
              className="col"
              ref={_formRef}
              formData={{ ...state.comment }}
              Fields={FormFields.Comment}
              onSubmit={handleCommentSubmit}
              onChange={handleFieldChange}
              onDeleteFile={handleDeleteFile} />
            <PrimaryButton
              label='Submit'
              icon={<FileTextOutlined style={{ color: '#FFF' }} />}
              className='btn-submit mr1'
              onClick={handleCommentSubmit} />
          </div>
        </div>
      }
    </div>
  )
}