import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  departments: [],
  taskPriorityTypes: [],
  incidentDeptResponders: [],
  continuityDeptResponders: [],
  taskDeptResponders: [],
  loading: false,
  alert: null,
  confirmation: null,
  InvalidToken: false,
}

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    fetchDepartments: () => {},
    fetchTaskPriorities: () => {},
    fetchTaskStatus: () => {},
    fetchIncidentDeptResponders: () => {},
    fetchContinuityDeptResponders: () => {},
    fetchTaskDeptResponders: () => {},
    downloadAttachment: () => {},
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
    setIncidentDeptResponders: (state, action) => {
      state.incidentDeptResponders = action.payload
    },
    setContinuityDeptResponders: (state, action) => {
      state.continuityDeptResponders = action.payload
    },
    setTaskDeptResponders: (state, action) => {
      state.taskDeptResponders = action.payload
    },
    setTaskProrities: (state, action) => {
      state.taskPriorityTypes = action.payload
    },
    setTaskStatus: (state, action) => {
      state.taskStatusTypes = action.payload
    },
    clearState: () => initialState,
  }
})
export const commonActions = commonSlice.actions;

ReducerRegistry.register("common", commonSlice.reducer)