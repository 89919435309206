import { useEffect, useState } from "react";
import { TableComponent } from "../../StatsTable";
import { useDispatch, useSelector } from "react-redux";
import { getPriorityStats, getCurrentUser, getDepartments, taskActions } from "../../../store";

const Field = { attribute: 'dateRange', type: 'select' }

const initPriorityRows = [
  { priority: 'Critical', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Critical' },
  { priority: 'High', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'High' },
  { priority: 'Medium', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Medium' },
  { priority: 'Low', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Low' },
  { priority: 'Minimal', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Minimal' },
]

const initRowClassName = {
  'Critical': 'Critical',
  'High': 'High',
  'Medium': 'Medium',
  'Low': 'Low',
  'Minimal': 'Minimal',
}
export const PriorityVsComplianceStats = (props) => {
  const arcStats = props
  const [responseData, setResponseData] = useState({});
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser);
  const departments = useSelector(getDepartments);
  const priorityStats = useSelector(getPriorityStats)

  const deptInitialRows = [...initPriorityRows]
  const PriorityColumns = [
    { label: '', attribute: 'priority', headerClassName: 'txt-left', isLabel: true, width: '7%' },
    { label: 'Max Aging<br/>(Days)', attribute: 'max_aging', width: '8%', headerClassName: 'bgDDFFFF' },
    { label: 'Avg Aging<br/>(Days)', attribute: 'avg_aging', width: '8%', headerClassName: 'bgDDFFFF' },
    { label: 'Total', attribute: 'Total', headerClassName: 'bgDDFFFF', width: '8%' },
    { label: 'Complete', attribute: 'Complete', headerClassName: '', width: '8%', colorIndicator: true },
    { label: 'Started', attribute: 'InProgress', headerClassName: '', width: '8%', colorIndicator: true },
    { label: 'Pending', attribute: 'Pending', headerClassName: '', width: '8%', colorIndicator: true },
    { label: 'Other', attribute: 'Other', headerClassName: '', width: '8%', colorIndicator: true },
  ]

  const [tableData, setTableData] = useState([...deptInitialRows]);

  const getDataFromServer = async (dateRange) => {
    let payload = {
      "date_range_type": "last_365_days",
      "start_date": "01-11-2023",
      "end_date": "20-11-2024",
      "stats_type": "priority_stats"
    };
    if (dateRange && dateRange.id) {
      payload.date_range_type = dateRange.id;
    }
    try {
      dispatch(taskActions.fetchArcStats(payload))
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  };
  useEffect(() => {
    // Prioritize priorityStats if available, otherwise use arcStats
    if (priorityStats && Object.keys(priorityStats).length > 0) {
      setResponseData(priorityStats);
    } else if (arcStats?.status_priority_list) {
      setResponseData(arcStats.status_priority_list);
    }
  }, [arcStats, priorityStats]);

  useEffect(() => {
    if (responseData && user && departments) {
      let tempTableData = getDataFromResponse(responseData, user, departments);
      setTableData([...tempTableData]);
    }
  }, [responseData, user, departments]);

  const DeptTableID = 'arc-priority-table';

  return (
    <>
      <TableComponent
        title='Priority-wise Compliance Status'
        topBarField={Field}
        rows={tableData}
        Columns={PriorityColumns}
        tableField={Field}
        tableID={DeptTableID}
        tableClassName='arc-stats-tbl'
        onDateChange={getDataFromServer}
      />
    </>
  );
}

const getDataFromResponse = (res, user, departments) => {

  if (res) {
    const brandname = user.ent_org.brandname;
    // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();
    let totalRow = { priority: brandname, max_aging: 0, avg_aging: 0, Total: 0, Complete: 0, InProgress: 0, Pending: 0, Other: 0, labelClassName: 'bgTotal' };
    let output = []
    const responseData = res
    if (Object.keys(responseData).length > 0) {
      let priority_dict = {}
      Object.keys(responseData).map((status_key) => {
        Object.keys(responseData[status_key]).map(priority_key => {
          if (!Boolean(priority_dict[priority_key])) {
            priority_dict[priority_key] = {}
          }
          priority_dict[priority_key][status_key] = responseData[status_key][priority_key]
        })
      })
      // Merge Default with Medium
      if ('Default' in priority_dict) {
        if (!priority_dict['Medium']) {
          priority_dict['Medium'] = {}
        }
        Object.keys(priority_dict['Default']).forEach(status_key => {
          if (!(Boolean(priority_dict['Medium'][status_key]))) {
            priority_dict['Medium'][status_key] = 0
          }
          priority_dict['Medium'][status_key] += priority_dict['Default'][status_key]
        })
      }

      let output_array = []
      let priorities = ['Critical', 'High', 'Medium', 'Low', 'Minimal']
      priorities.forEach(priority_key => {
        let row = { 'Total': 0, 'Complete': 0, 'InProgress': 0, 'Pending': 0, 'Other': 0, 'max_aging': 0, 'avg_aging': 0 };

        if (priority_key in priority_dict) {
          Object.keys(priority_dict[priority_key]).forEach(status_key => {
            if (['Complete', 'InProgress', 'Pending'].includes(status_key)) {
              row[status_key] = priority_dict[priority_key][status_key]
            }
            else {
              row['Other'] = priority_dict[priority_key][status_key]
            }
            row['Total'] += priority_dict[priority_key][status_key]
            row['max_aging'] += responseData[status_key]['max_aging'] || 0
            row['avg_aging'] += responseData[status_key]['avg_aging'] || 0
          })
        }
        row['labelClassName'] = initRowClassName[priority_key]
        row['priority'] = priority_key
        output_array.push(row)
      })
      let avgAgingCount = 0;
      for (let row of output_array) {
        Object.keys(row).forEach(key => {
          if (key !== 'priority' && key !== 'labelClassName' && key !== 'avg_aging' && key !== 'max_aging') {
            totalRow[key] += row[key]
          }
        })
        totalRow['max_aging'] = Math.max(totalRow['max_aging'], row['max_aging'])

        // Sum the avg_aging values for later averaging
        if (row['avg_aging'] > 0) {
          totalRow['avg_aging'] += row['avg_aging'];
          avgAgingCount++; // Increment the count only when avg_aging is valid
        }
      }
      // After the loop, calculate the correct average for avg_aging
      if (avgAgingCount > 0) {
        totalRow['avg_aging'] = totalRow['avg_aging'] / avgAgingCount; // Calculate the average
      }

      output_array.unshift(totalRow)
      output = output_array.filter(row => {
        return Object.entries(row).some(([key, value]) => (key !== 'priority' && value !== 0));
      });

      output.forEach((row) => {
        Object.keys(row).forEach((columnKey) => {
          if (row[columnKey] === 0 || row[columnKey] === '0') {
            row[columnKey] = '-'
          }
        })
      })
    }
    return output
  }
  else {
    return []
  }
}
