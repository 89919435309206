import React from 'react';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Modal as AntModal } from 'antd';
import { Button, PrimaryButton } from "../Button";
import "./style.scss";

const CancelButton = (props) => {
  const { label, danger, labelClassName, type, Icon, ...rest } = props;
  const iconColor = danger ? '#F00' : '#0133CC';
  const labelColor = danger ? 'cF00' : 'c0133CC';
  const BtnIcon = Icon || CloseCircleOutlined;
  return (
    <Button
      type={type || 'text'}
      label={label || 'Cancel'}
      icon={<BtnIcon style={{ color: iconColor }} />}
      labelClassName={`f14 bold-600 exo2 ${labelColor} ${labelClassName || ''}`}
      {...rest}
    />
  )
}

const ModalPrimaryButton = (props) => {
  const { label, labelClassName, isCancel, ...rest } = props;
  const Icon = isCancel ? CloseCircleOutlined : CheckCircleOutlined
  return (
    <PrimaryButton
      label={label || 'Cancel'}
      icon={<Icon style={{ color: '#FFF' }} />}
      labelClassName={`cFFF f14 bold-600 exo2 ${labelClassName || ''}`}
      {...rest}
    />
  )
}

const ModalFooter = (props) => {
  const { footeClassName, actions, CustomFooter } = props;
  return (
    Boolean(CustomFooter) ?
      <CustomFooter />
      : Array.isArray(actions) && actions.length > 0 ?
        <div className={`row ${footeClassName || 'h-end'}`}>
          {
            actions.map((action, i) => {
              let type = action.type, Component = Button;
              if (type === 'cancel') {
                type = undefined;
                Component = CancelButton
              } else if (type === 'primary') {
                type = undefined;
                Component = ModalPrimaryButton
              }
              return <Component {...action} type={type} key={i} />
            })
          }
        </div>
        : null
  )
}

export const Modal = (props) => {
  const { open, onClose, title, titleClassName, closeIcon, className, width, centered } = props;
  return (
    <AntModal
      centered={centered || true}
      rootClassName='neu-modal'
      className={`${className || ''}`}
      width={width || 100}
      footer={<ModalFooter {...props} />}
      open={open}
      title={
        <div className={`${titleClassName || ''}modal-header`}>
          {title}
          {closeIcon}
        </div>
      }
      onCancel={onClose}
      closeIcon={null} // closeIcon is added in custom header now
    >
      {props.children}
    </AntModal>
  )
}

export const TransparentDialog = (props) => {
  return (
    <Modal open className={`transparent-dialog ${props.className || ''}`}>
      <div className='dialog-container'>
        {props.children}
      </div>
    </Modal>
  )
}