import "./saga";
export * from "./slice";
export * from "./helpers";

const getUserState = (state) => state.user;

export const getLogInStatus = (state) => {
  let userState = getUserState(state);
  return userState.logInStatus
}
export const getReviewers = (state) => {
  let userState = getUserState(state);
  return userState.reviewers
}
export const getActiveUsers = (state) => {
  let userState = getUserState(state);
  return userState.activeUsers;
}
export const getFilteredActiveUsers = (iUserToFilter, state) => {
  const activeUsers = getActiveUsers(state);
  const options = [];
  if (Array.isArray(activeUsers) && Array.isArray(iUserToFilter)) {
    for (let i = 0; i < activeUsers.length; i++) {
      const { id, _listname, department_name, designation } = activeUsers[i];
      let index = iUserToFilter.findIndex(({ ent_usr_id }) => ent_usr_id === id);
      if (index == -1) {
        options.push({ id, label: `${_listname}, ${designation}, ${department_name}` })
      }
    }
  }
  return options;
}
export const isLoggedOut = (state) => {
  let userState = getUserState(state);
  return userState.isLoggedOut;
}
