import React from 'react';
import { Dropdown } from 'antd';
import { usePub } from "../Event";
import { CaretDownOutlined } from '@ant-design/icons';
import { AppLogo, ProfilePic, CompanyLogo, AuditorIcon, ArcIcon, ConsoleIcon, ControllerIcon, EducatorIcon, DocumentorIcon, InvestigatorIcon, ContinuerIcon, CentralIcon } from "../Assets";
import { useNavigate, Link } from "react-router-dom";
import "./style.scss";

const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;

const Modules = [
  { moduleId: 'auditor', Component: AuditorIcon, label: 'Auditor' },
  { moduleId: 'arc', Component: ArcIcon, label: 'Arc' },
  { moduleId: 'console', Component: ConsoleIcon, label: 'Console' },
  { moduleId: 'continuer', Component: ContinuerIcon, label: 'Continuer' },
  { moduleId: 'controller', Component: ControllerIcon, label: 'Controller', isAdmin: true },
  { moduleId: 'documenter', Component: DocumentorIcon, label: 'Documenter' },
  { moduleId: 'educator', Component: EducatorIcon, label: 'Educator' },
  { moduleId: 'investigator', Component: InvestigatorIcon, label: 'Investigator' },
  { moduleId: 'central', Component: CentralIcon, label: 'Central' },
]
export const getModuleLink = (moduleId, user) => {
  const sessionToken = user.session_token;
  return `https://${moduleId}.${DOMAIN}/login?sessionToken=${sessionToken}`;
}
export const NavBar = (props) => {
  const { user, isAdmin, isArchitect, currentModule } = props;
  const publish = usePub();
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    publish('SESSION_LOGOUT');
  }
  const getSubscribedModules = () => {
    let modules = Array.from(Modules);
    modules = modules.filter((mod) => {
      return mod.isAdmin ? (isAdmin || isArchitect) : mod.moduleId !== currentModule;
    })
    modules.sort((modA, modB) => {
      let moduleIdA = String(modA.moduleId).toLowerCase(),
        moduleIdB = String(modB.moduleId).toLowerCase();
      return moduleIdA.localeCompare(moduleIdB)
    })
    return modules
  }
  const ProfileDropdown = () => {
    return (
      <div className='col documenter-menus'>
        <div className='btn w-100 row btn-profile v-start no-border'>
          <ProfilePic className='profile-img' />
          <div className='col f-rest'>
            <div className='col w-100'>
              <span className='f18 reg usename'>{user.firstname} {user.lastname}</span>
              <span className='f12 reg c1155CC email'>{user.email}</span>
            </div>
            <div className='row h-btn profile-signout w-100'>
              <Link to={getModuleLink('console', user) + '&redirect=profile'} target='_blank' className='f14 profile' >Profile</Link>
              <Link className='f14 logout' onClick={handleLogout}>Sign Out</Link>
            </div>
          </div>
        </div>
        {
          getSubscribedModules().map(({ label, Component, moduleId }) => {
            return (
              <a className='row v-ctr f14 menu-entry link c00085' rel="noreferrer" key={moduleId} href={getModuleLink(moduleId, user)}>
                <Component className="mod-logo" />
                <span>{label}</span>
              </a>
            )
          })
        }
      </div>
    )
  }
  return (
    <nav className='row w-100 nav-bar h-btn'>
      <AppLogo className='logo' onClick={() => navigate('/')} />
      <div className='row'>
        <Dropdown
          overlayClassName="menu-overlay"
          dropdownRender={ProfileDropdown} placement="bottomRight" trigger={['click']} arrow>
          <div className='btn row btn-profile no-border'>
            <ProfilePic className='profile-img' />
            {/* <span className='f8 reg'>{user.firstname} {user.lastname}</span> */}
            <span className='f8'>
              <CaretDownOutlined className='c00085 icon' style={{ fontSize: '14px' }} />
            </span>
          </div>
        </Dropdown>
        <CompanyLogo user={user} />
      </div>
    </nav>
  )
}
export const useNavigateBack = () => {
  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.history && window.history.state) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }
  return navigateBack
}
export const useExternalLink = () => {
  let allLinks = null;
  const isExternalLink = (link) => {
    return !link.classList.contains('nm-link')
  }
  const handleExternalLink = (e) => {
    e.preventDefault();
    window.open(e.currentTarget.href, '_blank');
  }
  const init = (containers) => {
    containers = Array.isArray(containers) ? containers : [containers];
    containers.forEach(selector => {
      let links = window.document.querySelectorAll(`${selector} a`);
      if (allLinks === null) {
        allLinks = []
      }
      allLinks = allLinks.concat(Array.from(links))
      Array.prototype.map.call(allLinks, (link) => {
        if (isExternalLink(link)) {
          link.addEventListener('click', handleExternalLink)
        }
      })
    });

  }
  const unlink = () => {
    if (Array.isArray(allLinks)) {
      Array.prototype.map.call(allLinks, (link) => {
        link.removeEventListener('click', handleExternalLink)
      })
    }
    allLinks = null
  }
  const getAllLinks = () => {
    let res = []
    Array.prototype.map.call(allLinks, (link) => {
      if (isExternalLink(link)) {
        res.push(link)
      }
    })
    return res;
  }
  return { init, unlink, getAllLinks }
}