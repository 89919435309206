import React, { useState } from "react";
import { Dropdown } from "antd";
import { EditOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { getCurrentTask, taskActions, isDayjsObject } from "../../store";
import { Button, PrimaryButton, MenuButton, Form, IconAdd } from "../../components";
import { RemoveTaskResponder, AddTaskResponder, TransferTaskDept } from "./Dialogs";
import FormFields from "./FormFields.json";

const Properties = [
  { label: "Department:", attribute: 'department', canEdit: true },
  { label: "Source:", attribute: 'source', },
  { label: "Type:", attribute: 'activity_type', },
  { label: "Due:", attribute: 'due_date_str', canEdit: true },
  { label: "Created:", attribute: 'created_at', },
  { label: "Updated:", attribute: 'updated_at', },
  { label: "Aging:", attribute: 'aging', },
  { label: "Frequency:", attribute: 'repetition_occurs', },
]
export const TaskMenu = () => {
  const dispatch = useDispatch();
  const task = useSelector(getCurrentTask)
  const [state, setState] = React.useState({
    responderToRemove: null,
    responderToAdd: false,
    editDueDate: false,
    transferDept: false,
    duedate: null,
    showMenu: true, // New state to control visibility of the TaskMenu
  })
  const updateState = (newValues) => {
    setState((_) => ({ ..._, ...newValues }))
  }
  const handleRemoveResponder = (responder, e) => {
    e && e.stopPropagation && e.stopPropagation();
    updateState({ responderToRemove: responder })
  }
  const handleEdit = (attribute, e) => {
    e && e.stopPropagation && e.stopPropagation();
    if (attribute === Properties[0].attribute) {
      updateState({ transferDept: true, showMenu: false })
    } else if (attribute === Properties[1].attribute) {
      updateState({ editDueDate: true, duedate: task.due_date })
    }
  }
  const handleSaveDueDate = () => {
    if (isDayjsObject(state.duedate)) {
      let updatedVal = { due_date: state.duedate.toISOString() }
      dispatch(taskActions.updateTask({ taskId: task.id, updatedVal }))
    }
    updateState({ editDueDate: false, duedate: '' })
  }
  const duedate = (
    <React.Fragment>
      <div className="col exo2 f16 bold-600 label-sec">{Properties[1].label}</div>
      <div className="row f-rest h-btn">
        <Form
          hideErrorMsg
          className="col due-date-form"
          formData={{ due_date: state.duedate }}
          Fields={FormFields.DueDate}
          onChange={(e) => {
            updateState({ duedate: e.target.value })
          }} />
        <Button
          type='text'
          onClick={handleSaveDueDate}
          disabled={!Boolean(state.duedate)}
          icon={<CheckCircleOutlined style={{ color: '#0133CC' }} />}
        />
      </div>
    </React.Fragment>
  )
  const content = (
    <div
      onClick={(e) => {
        e && e.stopPropagation && e.stopPropagation();
      }}
      className="col task-menu-content">
      {
        Properties.map((_) => {
          return (
            <div className="row entry" key={_.attribute}>
              {
                (_.attribute === Properties[1].attribute && state.editDueDate) ?
                  duedate
                  :
                  <React.Fragment>
                    <div className="col exo2 f16 bold-600 label-sec">{_.label}</div>
                    <div className="row f-rest h-btn">
                      <span className="f16 reg">{task[_.attribute]}</span>
                      {
                        Boolean(_.canEdit) && !state.editDueDate &&
                        <Button
                          onClick={handleEdit.bind(null, _.attribute)}
                          icon={<EditOutlined style={{ color: '#1133CC' }} />}
                        />
                      }
                    </div>
                  </React.Fragment>
              }
            </div>
          )
        })
      }
      <div className="row w-100 entry v-start">
        <div className="col label-sec">
          <span className="exo2 f16 bold-600">Responder:</span>
          <PrimaryButton
            label='Add'
            className='add-res v-ctr h-ctr'
            labelClassName='bold-600 exo2 f14 line-12'
            onClick={() => updateState({ responderToAdd: true, showMenu: false })} // Hide TaskMenu when Add Responder is clicked
            icon={<IconAdd className='cFFF f16' />}
          />
        </div>
        <div className="col res-list f-rest oy-auto">
          {
            Array.isArray(task.task_responders) && task.task_responders.map(({ responder }, i) => {
              return (
                <div className="row w-100">
                  <span className="f14 reg line-12  c1133CC">{responder._listname}</span>
                  {
                    task.task_responders.length > 1 &&
                    <Button
                      type='text'
                      className='bgTrans'
                      onClick={handleRemoveResponder.bind(null, responder)}
                      icon={<CloseCircleOutlined style={{ color: '#F00' }} />}
                    />
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
  return (
    <React.Fragment>
      <Dropdown
        overlayClassName="task-menus"
        placement="bottomRight"
        menu={{ items: (state.showMenu) ? [{ key: '0', label: content }] : [] }}
        trigger={["click"]}
      >
        <MenuButton iconColor="#1133CC" onClick={(e) => e.preventDefault()} />
      </Dropdown>
      <RemoveTaskResponder
        responder={state.responderToRemove}
        open={(Boolean(state.responderToRemove))}
        onClose={handleRemoveResponder.bind(null, null)}
      />
      <AddTaskResponder
        open={state.responderToAdd}
        onClose={updateState.bind(null, { responderToAdd: false, showMenu: true })} // Show TaskMenu when modal is closed
      />
      <TransferTaskDept
        open={state.transferDept}
        onClose={updateState.bind(null, { transferDept: false, showMenu: true })}
      />
    </React.Fragment>
  )
}
export const ResponderCell = (text, task) => {
  const responders = task.task_responders || [];
  const getItems = () => {
    return responders.slice(1).map((responder, i) => {
      return { key: i, label: <span className="f14 c000 reg">{responder.responder._listname}</span> }
    })
  }
  return (
    <div className="row w-100 h-btn">
      <span className="f16 c00085 reg no-wrap">{responders[0] ? responders[0]?.responder?._listname : ''} </span>
      {
        Array.isArray(responders) && responders.length > 1 &&
        <Dropdown
          placement="bottom"
          menu={{ items: getItems() }}
          trigger={['click']}>
          <Button
            className='btn-text btn-responder'
            labelClassName='f12 c1133CC'
            onClick={(e) => e.preventDefault()}
            label={`+${responders.length - 1}`} />
        </Dropdown>
      }
    </div>
  )
}
export const TitleCell = ({ text = '' }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  let fullText = text;
  let truncatedText = text;
  const isTruncated = (text && text.length) > 48;

  if (isTruncated) {
    const trimPoint = text.lastIndexOf(' ', 48);
    truncatedText = text.substring(0, trimPoint) + '...';
  }

  return (
    <div
      className="row w-100 h-btn"
      onClick={toggleText}
    >
      {(!isExpanded) ? truncatedText : fullText}
      {/* {isTruncated && (
         <Button
                 className='btn-text btn-responder btn-title'
                 labelClassName='f12 c1133CC'
                 onClick={toggleText}
                 label='...' />

      )} */}
    </div>
  );
}