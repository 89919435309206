const Roles = {
  "Auditee": "Auditee",
  "Assessor": "Assessor",
  "Architect": "Architect",
  "Organiser": "Organiser",
  "Administrator": "Administrator"
}
export const getRoles = () => Roles;

export const getRolesList = (currentUserRole) => {
  const roles = { ...Roles }
  if (currentUserRole !== Roles.Administrator) {
    delete roles.Administrator;
  }
  if (currentUserRole !== Roles.Administrator && currentUserRole !== Roles.Architect) {
    delete roles.Architect;
  }
  if (currentUserRole !== Roles.Administrator && currentUserRole !== Roles.Architect && currentUserRole !== Roles.Organiser) {
    delete roles.Organiser;
  }
  return Object.keys(roles).map((k) => ({ id: k, label: roles[k] }))
}
export const isAdmin = (user) => {
  return user.role.name === Roles.Administrator
}
export const isOrganizer = (user) => {
  return user.role.name === Roles.Organiser
}
export const isAccessor = (user) => {
  return user.role.name === Roles.Assessor
}
export const isArchitect = (user) => {
  return user.role.name === Roles.Architect
}
export const isAuditee = (user) => {
  return user.role.name === Roles.Auditee
}
export const getCurrentUser = (state) => {
  return state.user.currentUser;
}
export const getUserAvatorName = (user) => {
  let { firstname, lastname } = user;
  firstname = String(firstname).trim();
  lastname = String(lastname).trim();
  let firstChar = firstname[0];
  let secondChar = lastname.length === 0 ? firstname[1] : lastname[0];
  return firstChar + secondChar;
}
